<template>
  <div class="w-100 position-relative" style="overflow-y: auto">
    <CCard class="pdf-wrapper">
      <div class="modal-body modal-container">
        <div class="pdf-wrapper">
          <div class="pdf-page fs-16">
            <div id="pdf1" class="pdf-content ml-3 mr-3">
              <div class="text-center fs-16">
                <strong class="bold">Mẫu C.III.3</strong><br>
                <strong>Văn bản thông báo kế hoạch tổ chức hoạt động</strong><br>
                <strong>xúc tiến không sử dụng ngân sách nhà nước</strong><br>
                <p class="font-italic">(Khoản 3, Điều 96 Nghị định số 31/2021/NĐ-CP ngày 26/3/2021)</p>
                <hr>
              </div>
              <div class="row fs-16">
                <div class="col-md-6">
                  <CInput class="custom-input" v-model="item.tenDonViToChuc" placeholder="TÊN ĐƠN VỊ TỔ CHỨC/CÁ NHÂN"
                          horizontal/>
                  <CInput class="custom-input" placeholder="Số:" v-model="item.soVanBan" horizontal/>
                </div>
                <div class="col-md-6">
                  <p class="text-center text-uppercase font-weight-bold">Cộng hòa xã hội chủ nghĩa Việt Nam</p>
                  <p class="text-center font-weight-bold">Độc lập – Tự do – Hạnh phúc</p>
                  <p class="font-weight font-italic" style="float: right; display: flex">
                    <CInput placeholder="Địa điểm:" horizontal :value.sync="item.diaDiem"/>
                    <span class="mt-2">, ngày {{getDay(item.ngayVanBan) }} tháng {{ getMonth(item.ngayVanBan) }} năm {{ getYear(item.ngayVanBan) }}</span>
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4" style="float: left">
                  <p class="font-weight">V/v thông báo kế hoạch tổ chức hoạt động xúc tiến hỗn hợp đầu tư, thương mại, du lịch, ngoại giao kinh tế</p>
                </div>
              </div>
              <div class="text-center">
                <p>Kính gửi: Bộ Kế hoạch và Đầu tư</p>
              </div>
              <div class="ml-3">
                <p><span class="pl-3">Căn cứ quy</span> định tại Khoản 3 Điều 96 Nghị định số 31/2021/NĐ-CP ngày
                  26/3/2021 quy định chi tiết và hướng dẫn thi hành một số điều của Luật Đầu tư, <span
                    class="font-italic">(Tên đơn vị tổ chức/Cá nhân)</span>
                  xin thông báo kế hoạch tổ chức các hoạt động xúc tiến đầu tư như sau
                </p>
              </div>
              <div class="row">
                <div class="col-xl-12" style="display: flex">
                  <div class="col-4">
                    <label>1. Tên hoạt động xúc tiến đầu tư:</label>
                  </div>
                  <div class="col-8">
                    <CInput placeholder="Nhập tên hoạt động đầu tư" horizontal v-model="item.tenHoatDong"/>
                  </div>
                </div>
                <div class="col-xl-12" style="display: flex">
                  <div class="col-4">
                    <label>2. Mục tiêu:</label>
                  </div>
                  <div class="col-8">
                    <CInput placeholder="Nhập mục tiêu" horizontal v-model="item.mucTieu"/>
                  </div>
                </div>
                <div class="col-xl-12" style="display: flex">
                  <div class="col-4">
                    <label>3. Thời gian thực hiện:</label>
                  </div>
                  <div class="col-4">
                    <v-date-picker class="" v-model="item.thoiGianThucHien" locale="vi" type="datetime">
                      <template v-slot="{ inputValue, inputEvents }">
                        <CInput
                          :value="inputValue"
                          v-on="inputEvents"
                          placeholder="Chọn ngày"
                        />
                      </template>
                    </v-date-picker>
                  </div>
                </div>
                <div class="col-xl-12">
                  <label class="ml-3">4. Địa điểm (trong nước/nước ngoài):</label>
                  <div class="col-xl-12" style="display: flex">
                    <div class="col-4">
                      <ul>
                        <li>Trong nước:</li>
                        <li class="mt-4">Nước ngoài:</li>
                      </ul>
                    </div>
                    <div class="col-8">
                      <CSelect class="form-group" placeholder="Chọn tỉnh thành" :value.sync="item.diaDiem_TrongNuoc"
                               :options="optionsTinhThanh" horizontal/>
                      <CSelect class="form-group" placeholder="Chọn quốc gia" :value.sync="item.diaDiem_NuocNgoai"
                               :options="optionsQuocGia" horizontal/>
                    </div>
                  </div>
                </div>
                <div class="col-xl-12" style="display: flex">
                  <div class="col-4">
                    <label>5. Thành phần dự kiến tham gia:</label>
                  </div>
                  <div class="col-8">
                    <CInput class="form-group" v-model="item.thanhPhanDuKienThamGia"
                            placeholder="Nhập các thành phần dự kiến tham gia" horizontal/>
                  </div>
                </div>
                <div class="col-xl-12" style="display: flex">
                  <div class="col-4">
                    <label>6. Quy mô hoạt động:</label>
                  </div>
                  <div class="col-8">
                    <CInput class="form-group" v-model="item.quyMoHoatDong" placeholder="Nhập quy mô hoạt động"
                            horizontal/>
                  </div>
                </div>
                <div class="col-xl-12">
                  <label class="ml-3">7. Lĩnh vực/Địa bàn kêu gọi đầu tư:</label>
                  <div class="col-xl-12" style="display: flex">
                    <div class="col-4">
                      <ul>
                        <li>Ngành/Lĩnh vực kêu gọi đầu tư:</li>
                        <li class="mt-4">Địa bàn kêu gọi đầu tư:</li>
                      </ul>
                    </div>
                    <div class="col-8">
                      <CSelect class="form-group" placeholder="Chọn ngành/lĩnh vực"
                               :value.sync="item.nganhLinhVucKeuGoiDauTuId" horizontal :options="optionsNganhLinhVuc"/>
                      <CSelect class="form-group" placeholder="Chọn địa bàn" :value.sync="item.diaBanKeuGoiDauTu" horizontal
                               :options="optionsTinhThanh"/>
                    </div>
                  </div>
                </div>
                <div class="col-xl-12 ml-3">
                  <label>8. Các hoạt động chính:
                    <span class="font-italic">(bao gồm danh mục giấy chứng nhận đăng ký đầu tư/chấp thuận chủ trương đầu
                      tư/thỏa thuận dự kiến trao/ký kết)</span>
                  </label>
                  <div class="col-12">
                    <CInput class="form-group" placeholder="Nhập các hoạt động chính" horizontal
                            v-model="item.cacHoatDongChinh"/>
                  </div>
                </div>
                <div class="col-xl-12">
                  <label class="ml-3">9. Cơ quan, tổ chức, doanh nghiệp, cá nhân phối hợp thực hiện:</label>
                  <div class="col-xl-12" style="display: flex">
                    <div class="col-4">
                      <ul>
                        <li>Tên tổ chức/cơ quan, doanh nghiệp, cá nhân trong nước:</li>
                        <li class="mt-3">Tên tổ chức/cơ quan, doanh nghiệp, cá nhân nước ngoài:</li>
                      </ul>
                    </div>
                    <div class="col-8">
                      <CInput class="form-group" v-model="item.toChucCoQuan_TrongNuoc"
                              placeholder="Chọn tên tổ chức/cơ quan trong nước" horizontal
                      />
                      <CInput class="form-group pt-3" v-model="item.toChucCoQuan_NuocNgoai"
                              placeholder="Chọn tên tổ chức/cơ quan nước ngoài" horizontal
                      />
                    </div>
                  </div>
                </div>
                <div class="col-xl-12 ml-3">
                  <label>10. Chương trình dự kiến:</label>
                  <div class="col-12">
                    <CInput class="form-group" v-model="item.chuongTrinhDuKien" placeholder="Nhập chương trình dự kiến"
                            horizontal
                    />
                  </div>
                </div>
                <div class="col-xl-12 ml-3">
                  <label>11. Phương án tổ chức thực hiện:</label>
                  <div class="col-12">
                    <CInput class="form-group" v-model="item.phuongAnToChucThucHien"
                            placeholder="Nhập phương án tổ chức thực hiện" horizontal
                    />
                  </div>
                </div>
                <div class="col-xl-12">
                  <label class="ml-3">12. Kinh phí:</label>
                  <div class="col-xl-12" style="display: flex">
                    <div class="col-4">
                      <ul>
                        <li>Ngân sách:</li>
                        <li class="mt-4">Nguồn xã hội hóa:</li>
                      </ul>
                    </div>
                    <div class="col-8">
<!--                      <CInput class="form-group" :value.sync="item.kinhPhi_NganSach"-->
<!--                              placeholder="Nhập kinh phí nguồn ngân sách" horizontal-->
<!--                      />-->
<!--                      <CInput class="form-group" :value.sync="item.kinhPhi_XaHoiHoa"-->
<!--                              placeholder="Nhập kinh phí nguồn xã hội hóa" horizontal/>-->
                      <input class="form-group" :value.sync="item.kinhPhi_NganSach" v-mask="'####-##'"
                              placeholder="Nhập kinh phí nguồn ngân sách">
                      <input class="form-group" :value.sync="item.kinhPhi_XaHoiHoa" v-mask="'####-##'"
                              placeholder="Nhập kinh phí nguồn xã hội hóa">
                    </div>
                  </div>
                </div>
                <div class="col-xl-12">
                  <label class="ml-3">13. Thông tin liên hệ:</label>
                  <div class="col-xl-12" style="display: flex">
                    <div class="col-2">
                      <ul>
                        <li>Họ tên:</li>
                        <li class="mt-4">Email:</li>
                      </ul>
                    </div>
                    <div class="col-4">
                      <CInput class="form-group" v-model="item.lienHe_HoTen" placeholder="Nhập họ tên" horizontal/>
                      <CInput class="form-group" v-model="item.lienHe_Email" placeholder="Nhập email" horizontal/>
                    </div>
                    <div class="col-2">
                      <ul>
                        <li class="">Số điện thoại:</li>
                      </ul>
                    </div>
                    <div class="col-4">
                      <CInput class="form-group" v-model="item.lienHe_SoDienThoai" placeholder="Nhập số điện thoại"
                              horizontal
                      />
                    </div>
                  </div>
                </div>
                <div class="col-xl-12 row">
                  <div class="col">
                    <div class="float-left">
                      <span class="font-italic font-weight-bold">Nơi nhận:</span> <br>
                      <span class="font-weight">- Như trên;</span> <br>
                      <span class="font-weight">- Bộ Kế Hoạch và Đầu tư (để t/b);</span> <br>
                      <span class="font-weight">- Lưu: VT,.......</span> <br>
                    </div>
                  </div>
                </div>
                <div class="col-xl-12">
                  <div class="float-right mr-5" style="text-align: center">
                    <span class="font-weight-bold text-uppercase">ĐẠI DIỆN CÓ THẨM QUYỀN CỦA ĐƠN VỊ TỔ CHỨC</span> <br>
                    <span class="font-italic text-align">(Ký, ghi rõ họ tên, chức danh và đóng dấu)</span><br>
                    <span class="font-weight-bold text-uppercase">CÁ NHÂN</span><br>
                    <span class="font-italic text-align">(Ký, ghi rõ họ tên)</span><br>
                    <br>
                    <br>
                    <br>
                    <br>
                    <br>
                    <span class="font-italic text-align">(Ký, ghi rõ họ tên, chức danh và đóng dấu)</span><br>
                  </div>
                </div>
              </div>
              <div class="form-group row mb-0 mt-3">
                <div class="col-12">
                  <b class="mt-5">Đính kèm file</b>
                  <FileUpload ref="upload" @onChange="changeFiles" :type="type"/>
                </div>
              </div>
            </div>
          </div>
        </div>
        <CCardFooter tag="div" class="text-center">
          <CButton color="primary" class="mr-2" @click="saveItem">Lưu lại</CButton>
          <CButton color="danger" class="mr-2" @click="saveItem2">Nộp văn bản</CButton>
          <CButton color="ghost" class="mr-2" @click="print">Xuất văn bản</CButton>
          <CButton color="secondary" class="mr-2" @click="cancel">Hủy bỏ</CButton>
<!--          <CButton color="secondary" class="mr-2" @click="fakeData">Fake Data</CButton>-->
        </CCardFooter>
      </div>
    </CCard>
  </div>
</template>

<script>
import { statuses, loaiDonVis } from '@/shared/appConstants'
import { mapGetters, mapActions } from 'vuex'
import { tinhThanhService } from '@/services/tinh-thanh.service'
import { quocGiaService } from '@/services/quoc-gia.service'
import { nganhLinhVucService } from '@/services/nganh-linh-vuc.service'
import { CREATE_THONGBAOTOCHUC } from '@/store/modules/VanBanTBTCHDXTDTKSDNSNN/actionTypes'
import moment from 'moment'
// import componentFuctions from '@/shared/componentFuctions'
import FileUpload from '@/components/file-upload/FileUpload'

export default {
  name: 'ThongBaoToChucCreate',
  components: {
    FileUpload
    // FilePond
  },
  data () {
    return {
      optionsTrangThai: statuses,
      optionsLoaiDonVi: loaiDonVis,
      item: {
        tenDonViToChuc: '',
        soVanBan: '',
        diaDiem: '',
        ngayVanBan: new Date(),
        tenHoatDong: '',
        mucTieu: '',
        thoiGianThucHien: '',
        diaDiem_TrongNuoc: '',
        diaDiem_NuocNgoai: '',
        thanhPhanDuKienThamGia: '',
        quyMoHoatDong: '',
        nganhLinhVucKeuGoiDauTuId: 0,
        diaBanKeuGoiDauTu: '',
        cacHoatDongChinh: '',
        toChucCoQuan_TrongNuoc: '',
        toChucCoQuan_NuocNgoai: '',
        chuongTrinhDuKien: '',
        phuongAnToChucThucHien: '',
        kinhPhi_NganSach: 0,
        kinhPhi_XaHoiHoa: 0,
        lienHe_HoTen: '',
        lienHe_SoDienThoai: '',
        lienHe_Email: '',
        dinhKem: '',
        soLanHieuChinh: 0,
        trangThai: 0
      },
      optionsTinhThanh: [],
      optionsQuocGia: [],
      optionsNganhLinhVuc: [],
      Attachments: [],
      type: 'VanBanTBTCHDXTDTKSDNSNN'
    }
  },
  computed: {
    ...mapGetters('thongBaoToChuc', {
      isSaving: 'isSaving',
      responseResult: 'responseResult'
    })
  },
  methods: {
    ...mapActions('thongBaoToChuc', { createItem: CREATE_THONGBAOTOCHUC }),
    async saveItem () {
      this.item.trangThai = 1
      this.item.dinhKem = JSON.stringify(this.Attachments)
      // this.item.ngayVanBan = String(this.item.ngayVanBan)
      // this.item.thoiGianThucHien = String(this.item.thoiGianThucHien)
      this.item.kinhPhi_NganSach = Number(this.item.kinhPhi_NganSach)
      this.item.kinhPhi_XaHoiHoa = Number(this.item.kinhPhi_XaHoiHoa)
      this.item.nganhLinhVucKeuGoiDauTuId = parseInt(this.item.nganhLinhVucKeuGoiDauTuId)
      if (Number(this.item.kinhPhi_NganSach) && Number(this.item.kinhPhi_XaHoiHoa) && Number(this.item.nganhLinhVucKeuGoiDauTuId)) {
        await this.createItem(this.item)
        console.log(this.item)
        if (this.responseResult.error) {
          this.$toast.error(this.responseResult.message)
        } else {
          this.$toast.success(this.responseResult.message)
          await this.$router.push({ path: '/thong-bao-to-chuc-hd-xtdt' })
        }
      } else {
        this.$toast.error('Kinh phí ngân sách và kinh phí xã hội hóa chưa đúng định dạng')
      }
    },
    async saveItem2 () {
      this.item.trangThai = 2
      this.item.dinhKem = JSON.stringify(this.Attachments)
      if (Number(this.item.kinhPhi_NganSach) && Number(this.item.kinhPhi_XaHoiHoa)) {
        await this.createItem(this.item)
        if (this.responseResult.error) {
          this.$toast.error(this.responseResult.message)
        } else {
          this.$toast.success(this.responseResult.message)
          await this.$router.push({ path: '/thong-bao-to-chuc-hd-xtdt' })
        }
      } else {
        this.$toast.error('Kinh phí ngân sách và kinh phí xã hội hóa chưa đúng định dạng')
      }
    },
    // fakeData () {
    //   const that = this
    //   that.item.tenDonViToChuc = 'Cơ Quan A'
    //   that.item.soVanBan = '12345678'
    //   that.item.diaDiem = 'Hà Nội'
    //   that.item.ngayVanBan = new Date()
    //   that.item.tenHoatDong = 'vốn nhà nước test'
    //   that.item.thoiGianThucHien = new Date()
    //   that.item.diaDiem_TrongNuoc = 'TP Hà Nội'
    //   that.item.diaDiem_NuocNgoai = 'Viet Nam'
    //   that.item.thanhPhanDuKienThamGia = 'Công ty A Công ty B'
    //   that.item.quyMoHoatDong = 'Lớn'
    //   that.item.nganhLinhVucKeuGoiDauTuId = 1
    //   that.item.diaBanKeuGoiDauTu = 'TP Hà Nội'
    //   that.item.cacHoatDongChinh = 'đăng ký chấp thuật chủ trương đầu tư'
    //   that.item.toChucCoQuan_TrongNuoc = 'Tổ chức A'
    //   that.item.toChucCoQuan_NuocNgoai = 'Tổ chức B'
    //   that.item.chuongTrinhDuKien = 'Chương trình A Chương trình B'
    //   that.item.phuongAnToChucThucHien = 'Phương án A Phương án B'
    //   that.item.kinhPhi_NganSach = 123123
    //   that.item.kinhPhi_XaHoiHoa = 123132
    //   that.item.lienHe_HoTen = 'quang'
    //   that.item.lienHe_SoDienThoai = '13212313'
    //   that.item.lienHe_Email = 'quang@gmail.com'
    //   that.item.dinhKem = ''
    //   that.item.soLanHieuChinh = 0
    //   that.item.trangThai = 0
    // },
    cancel () {
      this.$router.push({ path: '/thong-bao-to-chuc-hd-xtdt' })
    },
    print () {
      localStorage.setItem('thong_bao_to_chuc', JSON.stringify(this.item))
      // this.$router.push({ path: `/thong-bao-ke-hoach-xt-hon-hop/${this.item.id}/bieu-in` })
      const routeData = this.$router.resolve({ path: '/thong-bao-to-chuc-hd-xtdt/bieu-in' })
      window.open(routeData.href, '_blank')
    },
    changeFiles (data) {
      this.Attachments = data.ListFileAttachAdd
    },
    getDay: (time, format = 'DD/MM/YYYY') => time ? moment(time, format).format('DD') : '',
    getMonth: (time, format = 'DD/MM/YYYY') => time ? moment(time, format).format('MM') : '',
    getYear: (time, format = 'DD/MM/YYYY') => time ? moment(time, format).format('YYYY') : ''
  },
  async mounted () {
    this.optionsTinhThanh = await tinhThanhService.getAll()
    this.optionsNganhLinhVuc = await nganhLinhVucService.getAll()
    this.optionsQuocGia = await quocGiaService.getAll()
    // this.item.ngayVanBan = componentFuctions.getDateNow()
  }
}
</script>

<style scoped>
.custom-input {
  box-shadow: none;
  border: none;
  /*border-bottom: 1px dotted silver;*/
}
</style>
